import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { OfferContentConfiguration } from '@frontend/promo-homewidget';
import { ClientConfigService } from '@frontend/vanilla/core';

@Injectable()
export class SplashPageGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private offerContentConfiguration: OfferContentConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.offerContentConfiguration.isConfigReady) configs.push(OfferContentConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
