import { Injectable } from '@angular/core';

import { CommonService } from '@frontend/promo-homewidget';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { StaticPromotionDetails } from '../model/static-promotion.model';
import { StaticPromotionService } from '../static-promotion.service';
import { loadStaticPromotionData, loadStaticPromotionData_Success, load_Failed } from './static-promotion.actions';

@Injectable()
export class StaticPromotionEffects {
    loadStaticPromotionData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadStaticPromotionData.type),
            map((action: { promoId: string }) => action.promoId),
            mergeMap((promoId: string) =>
                this.staticPromotionService.loadStaticPromotionDetails(promoId).pipe(
                    map((response: StaticPromotionDetails) => loadStaticPromotionData_Success({ staticPromotionDetails: response })),
                    catchError(() => of(load_Failed({ errorMessage: 'Static Promotion is not loaded' }))),
                ),
            ),
        );
    });

    loadDataFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(load_Failed.type),
                tap(() => {
                    this.commonService.goToOverviewPage();
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private staticPromotionService: StaticPromotionService,
        private commonService: CommonService,
    ) {}
}
