import { Injectable } from '@angular/core';

import { NavigationService, ProductHomepagesConfig } from '@frontend/vanilla/core';

import { VIPHubConfiguration } from '../viphub-config';

@Injectable()
export class VIPHubRouteGuard {
    constructor(
        private vipHubConfiguration: VIPHubConfiguration,
        private productHomePagesConfig: ProductHomepagesConfig,
        private navigationService: NavigationService,
    ) {}

    canActivate() {
        if (this.vipHubConfiguration.enableVIPFeature) return true;

        this.navigationService.goTo(this.productHomePagesConfig.promo);
        return false;
    }
}
