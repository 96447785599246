import { importProvidersFrom } from '@angular/core';
import { Routes, mapToCanActivate } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { SmartRewardsResolver } from '@frontend/promo-smartwidget';
import { routeData } from '@frontend/vanilla/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientConfigActivateGuard } from './client-configs/guards/client-config-activate.guard';
import { FilterConfigActivateGuard } from './client-configs/guards/filter-config-activate.guard';
import { OffersEnhancementGuard } from './client-configs/guards/offers-enhancement-guard';
import { OffersGuard } from './client-configs/guards/offers.guard';
import { VIPHubRouteGuard } from './client-configs/guards/viphub-route.guard';
import { AuthorizationGuard } from './common/authorization.guard';
import { BratEffects } from './details/brat/store/brat.effects';
import { bratReducer } from './details/brat/store/reducers/brat.reducers';
import { CashbackEffects } from './details/targetedPromotions/cashback/store/cashback-effects';
import { cashbackReducer } from './details/targetedPromotions/cashback/store/cashback-reducer';
import { cashbackModuleStateKey } from './details/targetedPromotions/cashback/store/cashback-selectors';
import { ClickCardEffects } from './details/targetedPromotions/click-card/store/click-card.effects';
import { clickCardReducer } from './details/targetedPromotions/click-card/store/click-card.reducer';
import { clickCardModuleStateKey } from './details/targetedPromotions/click-card/store/click-card.selectors';
import { myCardsReducer } from './details/targetedPromotions/common/my-cards/store/my-cards.reducer';
import { myCardsModuleStateKey } from './details/targetedPromotions/common/my-cards/store/my-cards.selectors';
import { TargetPromoEffects } from './details/targetedPromotions/common/store/target-promotion.effects';
import { targetPromoReducer } from './details/targetedPromotions/common/store/target-promotion.reducer';
import { targetPromoModuleStateKey } from './details/targetedPromotions/common/store/target-promotion.selectors';
import { StaticPromotionEffects } from './details/targetedPromotions/static-promotion/details/store/static-promotion.effects';
import { staticPromotionReducer } from './details/targetedPromotions/static-promotion/details/store/static-promotion.reducers';
import { StaticPromotionGuard } from './details/targetedPromotions/static-promotion/static-promotion.guard';
import { LobbyActiveGuard } from './lobby/lobby.guard';
import { PromoInternalRouteGuard } from './promo-internal-route-guard';
import { SmartRewardGuard } from './smart-rewards/smart-reward.guard';
import { SplashPageGuard } from './splash-page/splash-page.guard';

export const appRoutes: Routes = [
    {
        path: '{culture}',
        children: [
            ...VANILLA_LAZY_ROUTES,
            {
                path: 'promo',
                children: [
                    {
                        /*On lobby page header is hidden when device is mobile, it is configured in dynacon,
                          if you change the path, make sure to adapt the dynacon configuration
https://admin.dynacon.bwin.corp/services/87656/features/103702/keys/103704/valuematrix?_matchAncestors=true#109953 */
                        path: 'lobby',
                        loadComponent: () => import(/* webpackChunkName: "lobby-module" */ './lobby/lobby.component').then((m) => m.LobbyComponent),
                        canActivate: [LobbyActiveGuard],
                    },
                    {
                        path: 'lcg-sports',
                        canActivate: [OffersGuard, OffersEnhancementGuard],
                        loadComponent: () =>
                            import('./offers/offers-navigation-sports-view/offers-navigation-sports-view.component').then(
                                (m) => m.OffersNavigationSportsViewComponent,
                            ),
                    },
                    {
                        path: 'offers',
                        loadChildren: () => import(/* webpackChunkName: "offers-module" */ './offers/offers.module').then((m) => m.OffersModule),
                    },
                    {
                        path: 'vip/home',
                        canActivate: mapToCanActivate([VIPHubRouteGuard]),
                        loadChildren: () =>
                            import(/* webpackChunkName: "vip-hub-feature" */ '@frontend/vip-hub-feature').then((m) => m.VIP_FEATURE_ROUTES),
                    },
                    {
                        path: 'coral-coins',
                        loadChildren: () =>
                            import(/* webpackChunkName: "coin-economy-route-module" */ './coin-economy/coin-economy-route.module').then(
                                (m) => m.routes,
                            ),
                    },
                    {
                        path: 'ladbucks',
                        loadChildren: () =>
                            import(/* webpackChunkName: "coin-economy-route-module" */ './coin-economy/coin-economy-route.module').then(
                                (m) => m.routes,
                            ),
                    },
                    {
                        path: 'smart-rewards/:smartRewardId',
                        canActivate: mapToCanActivate([SmartRewardGuard, ClientConfigActivateGuard]),
                        resolve: {
                            initData: SmartRewardsResolver,
                        },
                        loadComponent: () =>
                            import(/* webpackChunkName: "smart-rewards-component" */ './smart-rewards/smart-rewards.component').then(
                                (m) => m.SmartRewardsComponent,
                            ),
                    },
                    {
                        path: 'edssmartwidget',
                        loadComponent: () =>
                            import(
                                /* webpackChunkName: "eds-widget-view-module" */ './smart-rewards/eds-smart-widget-view/eds-smart-widget-view.component'
                            ).then((m) => m.EdsSmartWidgetViewComponent),
                    },
                    {
                        path: 'splashpage',
                        loadChildren: () =>
                            import(/* webpackChunkName: "splash-page-module" */ './splash-page/splash-page.routes').then((m) => m.SplashPageRoutes),
                        canActivate: [PromoInternalRouteGuard, SplashPageGuard],
                    },
                    {
                        path: 'betstationoffers',
                        loadChildren: () =>
                            import(/* webpackChunkName: "bet-station-module" */ './bet-station/bet-station.module').then(
                                (m) => m.BetStationViewModule,
                            ),
                    },
                    {
                        path: 'offershistory',
                        loadChildren: () =>
                            import(/* webpackChunkName: "offers-history-module" */ './offers/offers-history/offers-history.module').then(
                                (m) => m.OffersHistoryModule,
                            ),
                    },
                    {
                        path: 'promohistory',
                        loadChildren: () =>
                            import(/* webpackChunkName: "offers-history-module" */ './offers/offers-history-v2/offers-history-v2.module').then(
                                (m) => m.OffersHistoryV2Module,
                            ),
                    },
                    {
                        path: 'mygala',
                        loadChildren: () =>
                            import(/* webpackChunkName: "offers-cards-module" */ './offer-cards/offer-cards.module').then((m) => m.OfferCardsModule),
                    },
                    {
                        path: 'bonuses',
                        loadChildren: () =>
                            import(/* webpackChunkName: "bonus-module" */ './details/bonus/bonus-route.module').then((m) => m.BonusRoutes),
                        data: routeData({ authorized: true }),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'bingoticket',
                        loadChildren: () =>
                            import(/* webpackChunkName: "bingo-tickets-module" */ './details/bingoTickets/details/bingo-tickets.routes').then(
                                (m) => m.BingoTicketRoutes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'oddboost',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ood-boost-module" */ './details/odd-boost/odd-boost.routes').then((m) => m.OddBoostRoutes),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'oddboostpromo',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ood-boost-module" */ './details/odd-boost-V2/odd-boost-V2.routes').then(
                                (m) => m.OddBoostV2Routes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard],
                    },
                    {
                        path: 'pokerticket',
                        loadChildren: () =>
                            import(/* webpackChunkName: "poker-tickets-module" */ './details/pokerticket/pokerticket.module').then(
                                (m) => m.PokerticketModule,
                            ),
                    },
                    {
                        path: 'riskfreebet',
                        loadChildren: () =>
                            import(/* webpackChunkName: "risk-free-bet-module" */ './details/risk-free-bet/risk-free-bet.routes').then(
                                (m) => m.RiskFreeBetRoutes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'betinsurancepromo',
                        loadChildren: () =>
                            import(/* webpackChunkName: "risk-free-bet-module" */ './details/risk-free-bet-V2/risk-free-bet-V2.routes').then(
                                (m) => m.RiskFreeBetV2Routes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard],
                    },
                    {
                        path: 'betandget',
                        loadChildren: () =>
                            import(/* webpackChunkName: "bet-and-get-module" */ './details/bet-and-get/bet-and-get-routes').then(
                                (m) => m.BetandGetRoutes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'betandgetpromo',
                        loadChildren: () =>
                            import(/* webpackChunkName: "bet-and-get-module" */ './details/bet-and-get-details-v2/bet-and-get-v2-routes').then(
                                (m) => m.BetAndGetV2Routes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'freespins',
                        loadChildren: () =>
                            import(/* webpackChunkName: "freespins-module" */ './details/freespins/freespins.routes').then((m) => m.FreesSpinsRoutes),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'bonusspinspromo',
                        loadChildren: () =>
                            import(/* webpackChunkName: "freespins-module" */ './details/bonusspins/bonusspins-routes').then(
                                (m) => m.BonusspinsRoutes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'goldenchips',
                        loadChildren: () =>
                            import(/* webpackChunkName: "goldenchips-module" */ './details/goldenchips/goldenchips.routes').then(
                                (m) => m.GoldenChipsRoutes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'freebets',
                        loadChildren: () =>
                            import(/* webpackChunkName: "freebet-module" */ './details/freebet/freebet.routes').then((m) => m.FreebetRoutes),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard, OffersEnhancementGuard],
                    },
                    {
                        path: 'bonusbetpromo',
                        loadChildren: () =>
                            import(/* webpackChunkName: "freebet-V2-module" */ './details/freebet-details-V2/freebet-V2-routes').then(
                                (m) => m.FreebetV2Routes,
                            ),
                        canActivate: [ClientConfigActivateGuard, FilterConfigActivateGuard],
                    },
                    {
                        path: 'promotions/:product/:folderId',
                        loadChildren: () =>
                            import(/* webpackChunkName: "promotions-module" */ './details/promotions/promotions.module').then(
                                (m) => m.PromotionsModule,
                            ),
                    },
                    {
                        path: 'promotions/:product/:x1/:x2/:folderId',
                        loadChildren: () =>
                            import(/* webpackChunkName: "promotions-module" */ './details/promotions/promotions.module').then(
                                (m) => m.PromotionsModule,
                            ),
                    },
                    {
                        path: 'brat/:promoId',
                        canActivate: [AuthorizationGuard, ClientConfigActivateGuard],
                        providers: [importProvidersFrom(StoreModule.forFeature('brat', bratReducer), EffectsModule.forFeature([BratEffects]))],
                        loadChildren: () =>
                            import(/* webpackChunkName: "brat-module" */ './details/brat/brat-routing.module').then((m) => m.BratRoutesModule),
                        data: routeData({ authorized: false }),
                    },
                    {
                        path: 'bspin',
                        loadChildren: () => import(/* webpackChunkName: "bspin-module" */ './bspin/bspin.module').then((m) => m.BspinModule),
                        data: routeData({ allowAnonymous: true, allowAuthorized: true }),
                    },
                    {
                        path: 'bspin/:id',
                        loadChildren: () => import(/* webpackChunkName: "bspin-module" */ './bspin/bspin.module').then((m) => m.BspinModule),
                        data: routeData({ allowAnonymous: true, allowAuthorized: true }),
                    },
                    {
                        path: 'prizewheel',
                        loadChildren: () => import(/* webpackChunkName: "bspin-module" */ './bspin/bspin.module').then((m) => m.BspinModule),
                        data: routeData({ allowAnonymous: true, allowAuthorized: true }),
                    },
                    {
                        path: 'prizewheel/:id',
                        loadChildren: () => import(/* webpackChunkName: "bspin-module" */ './bspin/bspin.module').then((m) => m.BspinModule),
                        data: routeData({ allowAnonymous: true, allowAuthorized: true }),
                    },
                    {
                        path: 'pickabox',
                        loadChildren: () =>
                            import(/* webpackChunkName: "pick-a-box-module" */ './pick-a-box/pick-the-box-route.module').then(
                                (m) => m.pickTheBoxroutes,
                            ),
                        data: routeData({ allowAnonymous: true, allowAuthorized: true }),
                    },
                    {
                        path: 'lb',
                        loadChildren: () =>
                            import(/* webpackChunkName: "leaderboard-module" */ './details/targetedPromotions/leaderboard/leaderboard.module').then(
                                (m) => m.LeaderboardModule,
                            ),
                    },
                    {
                        path: 'pa/:promoId',
                        loadChildren: () =>
                            import(
                                /* webpackChunkName: "player-activity-module" */ './details/targetedPromotions/player-activity/player-activity.module'
                            ).then((m) => m.PlayerActivityModule),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'cc/:promoId',
                        canActivate: [AuthorizationGuard, ClientConfigActivateGuard],
                        providers: [
                            importProvidersFrom(
                                StoreModule.forFeature(targetPromoModuleStateKey, targetPromoReducer),
                                EffectsModule.forFeature([TargetPromoEffects, ClickCardEffects]),
                                StoreModule.forFeature(clickCardModuleStateKey, clickCardReducer),
                                StoreModule.forFeature(myCardsModuleStateKey, myCardsReducer),
                            ),
                        ],
                        loadChildren: () =>
                            import(/* webpackChunkName: "click-card-module" */ './details/targetedPromotions/click-card/click-card-routes').then(
                                (m) => m.ClickCardRoutes,
                            ),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'ccpromo/:promoId',
                        canActivate: [AuthorizationGuard, ClientConfigActivateGuard],
                        providers: [
                            importProvidersFrom(
                                StoreModule.forFeature(targetPromoModuleStateKey, targetPromoReducer),
                                EffectsModule.forFeature([TargetPromoEffects, ClickCardEffects]),
                                StoreModule.forFeature(clickCardModuleStateKey, clickCardReducer),
                                StoreModule.forFeature(myCardsModuleStateKey, myCardsReducer),
                            ),
                        ],
                        loadComponent: () =>
                            import(
                                /* webpackChunkName: "click-card-page-v2" */ './details/targetedPromotions/click-card-V2/click-card-page-v2/click-card-page-v2.component'
                            ).then((m) => m.ClickCardPageV2Component),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'cb/:promoId',
                        canActivate: [AuthorizationGuard, ClientConfigActivateGuard],
                        providers: [
                            importProvidersFrom(
                                StoreModule.forFeature(cashbackModuleStateKey, cashbackReducer),
                                EffectsModule.forFeature([CashbackEffects]),
                            ),
                        ],
                        loadChildren: () =>
                            import(/* webpackChunkName: "cashback-module" */ './details/targetedPromotions/cashback/cashback-routing.module').then(
                                (m) => m.cashBackRoutes,
                            ),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'cr/:promoId',
                        loadChildren: () =>
                            import(/* webpackChunkName: "card-rush-module" */ './details/targetedPromotions/card-rush/card-rush.module').then(
                                (m) => m.CardRushModule,
                            ),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'eds/:promoId',
                        loadChildren: () =>
                            import(/* webpackChunkName: "eds-module" */ './details/targetedPromotions/eds/eds.module').then((m) => m.EdsModule),
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'edswidget',
                        loadChildren: () =>
                            import(
                                /* webpackChunkName: "eds-widget-view-module" */ './details/targetedPromotions/eds/eds-widget-view/eds-widget-view.module'
                            ).then((m) => m.EdsWidgetViewModule),
                    },
                    {
                        path: 'depositoffers',
                        loadComponent: () =>
                            import(/* webpackChunkName: "deposit-offers-module" */ './cashier-deposit-offers/deposit-offers.component').then(
                                (m) => m.DepositOffersComponent,
                            ),
                        data: routeData({ authorized: true }),
                        canActivate: [OffersGuard],
                    },
                    {
                        path: 'staticpromo',
                        providers: [
                            importProvidersFrom(
                                StoreModule.forFeature('staticPromotionDetails', staticPromotionReducer),
                                EffectsModule.forFeature([StaticPromotionEffects]),
                            ),
                        ],
                        loadChildren: () =>
                            import(
                                /* webpackChunkName: "static-promotion-module" */ './details/targetedPromotions/static-promotion/static-promotion-routing.module'
                            ).then((m) => m.routes),
                        data: routeData({ authorized: true }),
                        canActivate: [StaticPromotionGuard, ClientConfigActivateGuard, AuthorizationGuard],
                    },
                    {
                        path: 'servererror',
                        loadChildren: () =>
                            import(/* webpackChunkName: "server-error-module" */ '@frontend/vanilla/features/server-error').then((m) => m.ROUTES),
                        data: routeData({ allowAnonymous: true }),
                    },
                    {
                        path: '**',
                        loadComponent: () =>
                            import(
                                /* webpackChunkName: "promo-content-loader-module" */ './promo-content-loader/promo-content-loader.component'
                            ).then((m) => m.PromoContentLoaderComponent),
                        data: routeData({ allowAnonymous: true, allowAuthorized: true }),
                    },
                ],
            },
            {
                path: '**',
                loadChildren: () => import(/* webpackChunkName: "not-found-module" */ '@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
            },
        ],
    },
    {
        path: '**',
        loadChildren: () => import(/* webpackChunkName: "not-found-module" */ '@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
    },
];
