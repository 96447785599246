import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { CashbackDetails } from '../cashback-models/cashback-details';
import { CashbackRequest } from '../cashback-models/cashback-request';

@Injectable({
    providedIn: 'root',
})
export class CashbackService {
    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getCashbackDetails(cashbackRequest: CashbackRequest): Observable<CashbackDetails> {
        const cashback$ = this.apiService.get('cashback', null, {
            params: cashbackRequest,
            showSpinner: true,
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(cashback$);
    }

    optedIn(promoId: string): Observable<CashbackDetails> {
        return this.apiService.get('cashback/optin', null, {
            params: { promoId },
        });
    }
}
