import { createReducer, on } from '@ngrx/store';

import { StaticPromotionDetails } from '../model/static-promotion.model';
import { StaticPromotionOptinResponse } from '../model/sttaic-promotion-optin-response.model';
import { loadStaticPromotionData, loadStaticPromotionData_Success, load_Failed } from './static-promotion.actions';

export interface StaticPromotionState {
    callState: CallState;
    staticPromotionDetails: StaticPromotionDetails;
    optinDetails: StaticPromotionOptinResponse;
}
export const initialState: StaticPromotionState = {
    callState: LoadingState.INIT,
    staticPromotionDetails: new StaticPromotionDetails(),
    optinDetails: new StaticPromotionOptinResponse(),
};

export type CallState = LoadingState | ErrorState;
export interface ErrorState {
    errorMsg: string;
}

export const enum LoadingState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
}

export const staticPromotionReducer = createReducer(
    initialState,
    on(loadStaticPromotionData, (state, action) => {
        return { ...state, ...action, callState: LoadingState.LOADING };
    }),
    on(loadStaticPromotionData_Success, (state, action) => {
        return { ...state, ...action, callState: LoadingState.LOADED };
    }),
    on(load_Failed, (state, action) => {
        return { ...state, ...action, callState: { errorMsg: action.errorMessage } };
    }),
);
