import { Component, ElementRef, NgZone, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';

import { IGlobalSearchContext } from '../context/search';
import { __CONTEXT__, __ENTRY_POINT_ACTIVE__, __FETCH_CONTEXT__, __LAUNCH_SEARCH__, __PRESENTATION_BOOTSTRAPPED__ } from '../events';

@Component({
    selector: 'gs-entry-sticky',
    templateUrl: './sticky-entry.component.html',
})
export class StickyEntryComponent implements OnInit, OnDestroy, OnChanges {
    private searchContext: IGlobalSearchContext | undefined;
    @ViewChild('container') container: ElementRef | undefined;
    @ViewChild('item') item: ElementRef | undefined;

    constructor(
        private readonly deviceService: DeviceService,
        // private readonly searchLauncherService: SearchLauncherService,
        private _ngZone: NgZone,
    ) {}

    private displayIcon = true;
    private shouldHideFloatingEntry = false;
    private displayImage = false;
    private wrapperClassValue = 'fab-search-wrapper';
    private iconClassValue = 'fab-search-icn theme-search';
    private imageSourceValue = '#';

    get isEnabled(): boolean {
        return (
            (this.searchContext &&
                this.searchContext.appContext &&
                this.searchContext.appContext.isEnabled &&
                this.deviceService.isTouch &&
                !this.shouldHideFloatingEntry) ||
            false
        );
    }

    ngOnInit() {
        this.setState(this.searchContext);
        this.registerEventHandlers();
        this.raiseIsAppEnabledQuery();
        if (
            this.searchContext &&
            this.searchContext.presentationContext &&
            this.searchContext.presentationContext.configuration &&
            this.searchContext?.presentationContext?.configuration?.DraggableStickyIcon &&
            this.searchContext.presentationContext?.configuration?.DraggableStickyIcon === 'true'
        ) {
            this.draggablestickyicon();
        }
    }

    ngOnChanges() {
        this.setState(this.searchContext);
        this.registerEventHandlers();
        this.raiseIsAppEnabledQuery();
        if (
            this.searchContext &&
            this.searchContext.presentationContext &&
            this.searchContext.presentationContext.configuration &&
            this.searchContext?.presentationContext?.configuration?.DraggableStickyIcon &&
            this.searchContext.presentationContext?.configuration?.DraggableStickyIcon === 'true'
        ) {
            this.draggablestickyicon();
        }
    }

    ngOnDestroy(): void {
        this.unregisterEventHandlers();
    }
    private readonly registerDraggableStickyIcon = (event: Event): void => {
        if (event) {
            setTimeout(() => {
                if (
                    this.searchContext &&
                    this.searchContext.presentationContext &&
                    this.searchContext.presentationContext.configuration &&
                    this.searchContext?.presentationContext?.configuration?.DraggableStickyIcon &&
                    this.searchContext?.presentationContext?.configuration?.DraggableStickyIcon === 'true'
                ) {
                    this.draggablestickyicon();
                }
            }, 100);
        }
    };
    draggablestickyicon() {
        const dragItem = document.querySelector('#item');
        const container = document.querySelector('#container');

        let active = false;
        let currentX: any;
        let currentY: any;
        let initialX: any;
        let initialY: any;
        let xOffset = 0;
        let yOffset = 0;
        if (container) {
            container.addEventListener('touchstart', dragStart, false);
            container.addEventListener('touchend', dragEnd, false);
            container.addEventListener('touchmove', drag, false);

            container.addEventListener('mousedown', dragStart, false);
            container.addEventListener('mouseup', dragEnd, false);
            container.addEventListener('mousemove', drag, false);
        }
        function dragStart(e: any) {
            if (e.type === 'touchstart') {
                initialX = e.touches[0].clientX - xOffset;
                initialY = e.touches[0].clientY - yOffset;
            } else {
                initialX = e.clientX - xOffset;
                initialY = e.clientY - yOffset;
            }
            active = true;
        }

        function dragEnd(e: any) {
            if (e) {
                initialX = currentX;
                initialY = currentY;
                active = false;
            }
        }

        function drag(e: any) {
            if (active) {
                if (e.cancelable) {
                    e.preventDefault();
                }
                if (e.type === 'touchmove') {
                    currentX = e.touches[0].clientX - initialX;
                    currentY = e.touches[0].clientY - initialY;
                    if (e.touches[0].clientX >= 66 && e.touches[0].clientX < window.screen.width - 66) {
                        if (e.touches[0].clientY >= 66 && e.touches[0].clientY < window.innerHeight - 66) {
                            xOffset = currentX;
                            yOffset = currentY;
                            setTranslate(currentX, currentY, dragItem);
                        }
                    }
                } else {
                    currentX = e.clientX - initialX;
                    currentY = e.clientY - initialY;
                    if (e.clientX >= 66 && e.clientX < window.screen.width - 66) {
                        if (e.clientY > 0 && e.clientY < window.innerHeight - 66) {
                            xOffset = currentX;
                            yOffset = currentY;
                            setTranslate(currentX, currentY, dragItem);
                        }
                    }
                }
            }
        }

        function setTranslate(xPos: any, yPos: any, el: any) {
            if (el) {
                el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)';
            }
        }
    }

    launchSearch(): void {
        dispatchEvent(new CustomEvent(__LAUNCH_SEARCH__, { detail: { launchChannel: 'mobile', launchedFrom: 'bottom-sticky' } }));
    }

    get canDisplayIcon(): boolean {
        return this.displayIcon;
    }

    get canDisplayImage(): boolean {
        return this.displayImage;
    }

    get wrapperClass(): string {
        return this.wrapperClassValue;
    }

    get iconClass(): string {
        return this.iconClassValue;
    }

    get imageSource(): string {
        return this.imageSourceValue;
    }

    private setState(searchContext?: IGlobalSearchContext) {
        this.wrapperClassValue = this.getWrapperClassFromClientConfig(searchContext);

        if (!this.wrapperClassValue) {
            this.wrapperClassValue = 'fab-search-wrapper';
        }

        this.iconClassValue = this.getIconClassFromClientConfig(searchContext);
        if (this.iconClassValue) {
            this.displayIcon = true;
            this.displayImage = false;
        } else {
            this.iconClassValue = 'fab-search-icn theme-search';
            this.displayIcon = true;
            this.displayImage = false;
        }

        this.imageSourceValue = this.getImageSrcFromClientConfig(searchContext);
        if (this.imageSourceValue) {
            this.displayIcon = false;
            this.displayImage = true;
        }
        this.searchContext = searchContext;

        this.shouldHideFloatingEntry = !((searchContext && searchContext.appContext && searchContext.appContext.isEnabled) || false);
        if (!this.shouldHideFloatingEntry) {
            this.shouldHideFloatingEntry = this.shouldHideFloatingEntryPoint(searchContext);
        }
        if (this.isEnabled) {
            dispatchEvent(new CustomEvent(__ENTRY_POINT_ACTIVE__, { detail: { activeFrom: 'bottom-sticky' } }));
            if (
                this.searchContext &&
                this.searchContext.presentationContext &&
                this.searchContext.presentationContext.configuration &&
                this.searchContext?.presentationContext?.configuration?.DraggableStickyIcon &&
                this.searchContext?.presentationContext?.configuration?.DraggableStickyIcon === 'true'
            ) {
                this.draggablestickyicon();
            }
        }
    }

    private getWrapperClassFromClientConfig(searchContext?: IGlobalSearchContext): string {
        return (
            searchContext?.presentationContext?.entryPoints?.['sticky']?.parameters['WrapperClass'] ||
            searchContext?.presentationContext?.entryPoints?.['sticky']?.parameters['wrapperClass'] ||
            ''
        );
    }

    private getIconClassFromClientConfig(searchContext?: IGlobalSearchContext): string {
        return (
            searchContext?.presentationContext?.entryPoints?.['sticky']?.parameters['IconClass'] ||
            searchContext?.presentationContext?.entryPoints?.['sticky']?.parameters['iconClass'] ||
            ''
        );
    }

    private getImageSrcFromClientConfig(searchContext?: IGlobalSearchContext): string {
        return searchContext?.presentationContext?.entryPoints?.['sticky']?.image?.src || '';
    }

    private raiseIsAppEnabledQuery() {
        dispatchEvent(new CustomEvent(__FETCH_CONTEXT__));
    }

    private readonly hideFloatingEntryEventHandler = (event: Event): void => {
        this.shouldHideFloatingEntry = true;
        if (event && event?.stopImmediatePropagation && typeof event?.stopImmediatePropagation === typeof (() => {})) {
            event?.stopImmediatePropagation();
        }
    };

    private readonly showFloatingEntryEventHandler = (event: Event): void => {
        this.shouldHideFloatingEntry =
            this.searchContext?.presentationContext?.configuration?.HideFloatingEntryPoint?.trim()?.toLowerCase() == 'true' ? true : false;
        if (event && event?.stopImmediatePropagation && typeof event?.stopImmediatePropagation === typeof (() => {})) {
            event?.stopImmediatePropagation();
        }
    };

    private readonly messageEventHandler = (messageEvent: MessageEvent): void => {
        if (
            messageEvent &&
            messageEvent.data &&
            messageEvent.data.eventIdentifier &&
            typeof messageEvent.data.eventIdentifier === typeof '' &&
            messageEvent.data.eventIdentifier?.trim()
        ) {
            switch (messageEvent.data.eventIdentifier?.trim().toLowerCase()) {
                case 'gs:hidefloatingentry':
                    this.hideFloatingEntryEventHandler(messageEvent);
                    break;
                case 'gs:showfloatingentry':
                    this.showFloatingEntryEventHandler(messageEvent);
                    break;
                default:
                    break;
            }
        }
    };

    private shouldHideFloatingEntryPoint(searchcontext?: IGlobalSearchContext): boolean {
        return searchcontext?.presentationContext?.configuration?.HideFloatingEntryPoint?.trim().toLowerCase() === 'true' || false;
    }

    private readonly presentationBootstarppedEventHandler = (messageEvent: CustomEventInit) => {
        if (messageEvent && messageEvent.detail && messageEvent.detail) {
            const searchHost: any = messageEvent.detail;
            // this.isEnabled = searchHost.searchContext.appContext.isEnabled || false;
            this.setState(searchHost.searchContext);
        }
    };

    private readonly contextResultEventHandler = (customEvent: CustomEventInit) => {
        if (customEvent && customEvent?.detail) {
            //this.isEnabled = customEvent?.detail?.appContext.isEnabled || false;
            this.setState(customEvent?.detail);
        }
    };

    private registerEventHandlers() {
        this._ngZone.runOutsideAngular(() => {
            addEventListener(__ENTRY_POINT_ACTIVE__, this.registerDraggableStickyIcon);
            addEventListener('gs:hideFloatingEntry', this.hideFloatingEntryEventHandler);
            addEventListener('gs:showFloatingEntry', this.showFloatingEntryEventHandler);

            addEventListener('gs:classifyFloatingEntry', this.classifyFloatingEntry);
            addEventListener('gs:deClassifyFloatingEntry', this.deClassifyFloatingEntry);

            // if (this.globalSearchConfig.legacyMode) {
            //     addEventListener(SearchAppLoadedEventIdentifier, this.searchAppLoadedEventHandler);
            //     addEventListener(SearchAppQueryResultEventIdentifier, this.searchAppQueryResultHandler);
            // }
            // else {
            addEventListener(__CONTEXT__, this.contextResultEventHandler);
            addEventListener(__PRESENTATION_BOOTSTRAPPED__, this.presentationBootstarppedEventHandler);
            //}

            addEventListener('message', this.messageEventHandler);
        });
    }

    private unregisterEventHandlers() {
        this._ngZone.runOutsideAngular(() => {
            removeEventListener('gs:hideFloatingEntry', this.hideFloatingEntryEventHandler);
            removeEventListener('gs:showFloatingEntry', this.showFloatingEntryEventHandler);

            removeEventListener('gs:classifyFloatingEntry', this.classifyFloatingEntry);
            removeEventListener('gs:deClassifyFloatingEntry', this.deClassifyFloatingEntry);

            // if (this.globalSearchConfig.legacyMode) {
            //     removeEventListener(SearchAppLoadedEventIdentifier, this.searchAppLoadedEventHandler);
            //     removeEventListener(SearchAppQueryResultEventIdentifier, this.contextResultEventHandler);
            // }
            // else {
            removeEventListener(__CONTEXT__, this.contextResultEventHandler);
            removeEventListener(__PRESENTATION_BOOTSTRAPPED__, this.presentationBootstarppedEventHandler);
            //}

            removeEventListener('message', this.messageEventHandler);
        });
    }

    private readonly classifyFloatingEntry = (event: Event) => {
        const className: string = (event as CustomEvent)?.detail;
        if (className && !(this.wrapperClassValue && this.wrapperClassValue?.split(' ').some((x) => x === className))) {
            this.wrapperClassValue = `${this.wrapperClassValue} ${className}`;
        }
    };

    private readonly deClassifyFloatingEntry = (event: Event) => {
        const className: string = (event as CustomEvent)?.detail;
        const wrapperClassValues: string[] = (this.wrapperClassValue && this.wrapperClassValue.split(' ')) || [];
        if (className && wrapperClassValues.length > 0) {
            const newClassValues: string[] = wrapperClassValues.filter((x) => x !== className);
            if (newClassValues) {
                this.wrapperClassValue = newClassValues.join(' ');
            }
        }
    };
}
