import { Injectable } from '@angular/core';

import { TrackingModelService } from '@frontend/promo-homewidget';
import { ToastrQueueService } from '@frontend/vanilla/core';
import { Actions, EffectNotification, OnRunEffects, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, takeUntil } from 'rxjs/operators';

import { RevealCardRequest } from '../../common/models/my-cards/earned-card/reveal-card-request';
import { MyCardsContent, MyCardsStateModel } from '../../common/models/my-cards/my-cards-state-models';
import { TargetPromotionContent } from '../../common/models/target-promotion-content';
import { TargetPromotionMetadata } from '../../common/models/target-promotion-metadata';
import { getRevealCardData, loadMyCardsData, userRevealedMyCard_Success } from '../../common/my-cards/store/my-cards.actions';
import {
    loadTargetPromotionData_Fail,
    loadTargetPromotionData_Success,
    updateResultsContent,
    updateTabs,
    userOptInFailedForPromotion,
    userOptInInvalidPromoIdForPromotion,
    userOptInSuccessForPromotion,
    userOptedInForPromotion,
} from '../../common/store/target-promotions.actions';
import { ClickCardContent } from '../click-card-models/click-card-content';
import { ClickCardDetails } from '../click-card-models/click-card-details';
import { ClickCardMetaData } from '../click-card-models/click-card-metadata';
import { ClickCardRequest } from '../click-card-models/click-card-request';
import { ClickCardService } from '../click-card-service/click-card.service';
import {
    clickCardDestroyed,
    clickCardInit,
    clickCardOptInSuccess,
    loadClickCardData,
    loadClickCardData_Success,
    loadToastr,
    revealCard,
} from './click-card.actions';

@Injectable()
export class ClickCardEffects implements OnRunEffects {
    loadClickCardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadClickCardData.type),
            map((action: ClickCardRequest) => action),
            mergeMap((request) => {
                return this.clickCardService.getClickCardDetails(request).pipe(
                    map((response: ClickCardDetails) =>
                        loadTargetPromotionData_Success({
                            metadata: response.clickCardMetadata,
                            content: response.clickCardContent,
                        }),
                    ),
                    catchError(() => {
                        if (request?.isAutoOptin) {
                            userOptInInvalidPromoIdForPromotion();
                            this.trackingModelService.submitTracking({
                                CategoryEvent: 'promo hub',
                                LabelEvent: 'opt in',
                                ActionEvent: 'error',
                                PositionEvent: request.promoId,
                                EventDetails: 'promotion either expired/invalid',
                                LocationEvent: 'offer page',
                            });
                        }
                        return of(loadTargetPromotionData_Fail());
                    }),
                );
            }),
        );
    });

    loadClickCardData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData_Success),
            map((action: { metadata: TargetPromotionMetadata; content: TargetPromotionContent }) => {
                const details = new ClickCardDetails();
                details.clickCardMetadata = action.metadata as ClickCardMetaData;
                details.clickCardContent = action.content as ClickCardContent;
                return details;
            }),
            map((response) => loadClickCardData_Success(response)),
        );
    });

    loadMyCardsData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData_Success),
            map((action: { metadata: TargetPromotionMetadata; content: TargetPromotionContent }) => {
                const details = new ClickCardDetails();
                details.clickCardMetadata = action.metadata as ClickCardMetaData;
                details.clickCardContent = action.content as ClickCardContent;
                return details;
            }),
            map((response) => loadMyCardsData(this.getMyCardsStateModel(response))),
        );
    });

    loadOptInClickCardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userOptedInForPromotion.type),
            map((action: { promoId: string }) => action.promoId),
            mergeMap((promoId) =>
                this.clickCardService.optedIn(promoId).pipe(
                    map((response: ClickCardDetails) => clickCardOptInSuccess(response)),
                    catchError(() => {
                        of(userOptInFailedForPromotion());
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    userOptInSuccessForPromotion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clickCardOptInSuccess),
            map((response) =>
                userOptInSuccessForPromotion({
                    optinDetails: response.clickCardMetadata.optinDetails,
                    optinContent: response.clickCardContent.optinContent,
                }),
            ),
        );
    });

    loadOptinClickCardData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clickCardOptInSuccess),
            map((response) => loadClickCardData_Success(response)),
        );
    });

    loadMyCardsDataAfterOptin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clickCardOptInSuccess),
            map((response) => loadMyCardsData(this.getMyCardsStateModel(response))),
        );
    });

    loadToastr$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clickCardOptInSuccess),
            map((response) => loadToastr(response)),
        );
    });

    updateTabs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clickCardOptInSuccess, revealCard),
            map((response) => updateTabs({ tabs: response.clickCardContent.tabs })),
        );
    });

    updateResultsContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clickCardOptInSuccess, revealCard),
            map((response) => updateResultsContent({ resultsContent: response.clickCardContent.resultsContent })),
        );
    });

    loadClickCardRevealCardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getRevealCardData.type),
            map((action: RevealCardRequest) => action),
            mergeMap((request) =>
                this.clickCardService.revealMyCard(request.cardId, request.promoId).pipe(
                    map((response: ClickCardDetails) => revealCard(response)),
                    catchError(() => {
                        of(this.toastrQueueService.add('RevealCardError'));
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    userRevealedMyCard_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(revealCard),
            map((response) => userRevealedMyCard_Success(this.getRevealMyCardsStateModel(response))),
        );
    });

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(clickCardInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(clickCardDestroyed.type))))),
        );
    }

    constructor(
        private actions$: Actions,
        private toastrQueueService: ToastrQueueService,
        private clickCardService: ClickCardService,
        private trackingModelService: TrackingModelService,
    ) {}

    private getMyCardsStateModel(response: ClickCardDetails): MyCardsStateModel {
        const myCardsStateModel = new MyCardsStateModel();
        const clickCardMetaData = response.clickCardMetadata;
        const clickCardContent = response.clickCardContent;
        myCardsStateModel.myCardsMetaData = {
            numberOfExpiredCards: clickCardMetaData.numberOfExpiredCards,
            numberOfUnClickedCards: clickCardMetaData.numberOfUnClickedCards,
        };
        myCardsStateModel.criteriaCardStatus = clickCardContent.criteriaCardStatus;
        myCardsStateModel.earnedCardResultDetails = clickCardContent.earnedCardResultDetails;
        myCardsStateModel.multiCardsImage = clickCardContent.multiCardsImage;
        const myCardsDetails = clickCardContent.myCardsDetails;
        const playerPromoConfiguration = response.clickCardMetadata.playerPromoConfiguration;
        if (myCardsDetails !== undefined && playerPromoConfiguration !== undefined) {
            myCardsStateModel.myCardsContent = {
                myEarnedCardsContent: myCardsDetails.myEarnedCardsContent,
                earnedCardDetails: playerPromoConfiguration?.earnedCardDetails,
                earnedCardsImages: clickCardContent.cards,
            } as MyCardsContent;

            const criteriaCard = myCardsDetails.criteriaCard;
            if (criteriaCard !== undefined) {
                myCardsStateModel.myCardsContent.criteriaCard = {
                    completedCriteria: criteriaCard.completedCriteria,
                    criteriaCardDetails: criteriaCard.criteriaCardDetails,
                    criteriaCardsLength:
                        playerPromoConfiguration.criteriaProgress.totalCardsToEarn - playerPromoConfiguration.criteriaProgress.currentEarnedCards,
                };
            }
        }

        return myCardsStateModel;
    }

    private getRevealMyCardsStateModel(response: ClickCardDetails): MyCardsStateModel {
        const myCardsStateModel = new MyCardsStateModel();
        const clickCardMetaData = response.clickCardMetadata;
        const clickCardContent = response.clickCardContent;
        myCardsStateModel.myCardsMetaData = {
            numberOfExpiredCards: clickCardMetaData.numberOfExpiredCards,
            numberOfUnClickedCards: clickCardMetaData.numberOfUnClickedCards,
        };
        myCardsStateModel.earnedCardResultDetails = clickCardContent.earnedCardResultDetails;
        const playerPromoConfiguration = clickCardMetaData.playerPromoConfiguration;
        if (playerPromoConfiguration !== undefined) {
            myCardsStateModel.myCardsContent = {
                myEarnedCardsContent: clickCardContent.myCardsDetails?.myEarnedCardsContent,
                earnedCardDetails: playerPromoConfiguration.earnedCardDetails,
            } as MyCardsContent;
        }

        return myCardsStateModel;
    }
}
