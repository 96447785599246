import { Injectable } from '@angular/core';

import { TrackingModelService } from '@frontend/promo-homewidget';
import { Actions, EffectNotification, OnRunEffects, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, takeUntil } from 'rxjs/operators';

import { TargetPromotionContent } from '../../common/models/target-promotion-content';
import { TargetPromotionMetadata } from '../../common/models/target-promotion-metadata';
import {
    loadTargetPromotionData_Fail,
    loadTargetPromotionData_Success,
    updateResultsContent,
    updateTabs,
    userOptInFailedForPromotion,
    userOptInInvalidPromoIdForPromotion,
    userOptInSuccessForPromotion,
    userOptedInForPromotion,
} from '../../common/store/target-promotions.actions';
import { CashbackContent } from '../cashback-models/cashback-content';
import { CashbackDetails } from '../cashback-models/cashback-details';
import { CashbackMetaData } from '../cashback-models/cashback-metadata';
import { CashbackRequest } from '../cashback-models/cashback-request';
import { CashbackService } from '../cashback-service/cashback.service';
import { cashbackDestroyed, cashbackInit, loadCashbackData, loadCashbackDataOptin_Success, loadCashbackData_Success } from './cashback-actions';

@Injectable()
export class CashbackEffects implements OnRunEffects {
    loadCashBackData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCashbackData.type),
            map((action: CashbackRequest) => action),
            mergeMap((request) =>
                this.cashbackServie.getCashbackDetails(request).pipe(
                    map((response: CashbackDetails) =>
                        loadTargetPromotionData_Success({
                            metadata: response.cashbackMetadata,
                            content: response.cashbackContent,
                        }),
                    ),
                    catchError(() => {
                        if (request?.isAutoOptin) {
                            userOptInInvalidPromoIdForPromotion();
                            this.trackingModelService.submitTracking({
                                CategoryEvent: 'promo hub',
                                LabelEvent: 'opt in',
                                ActionEvent: 'error',
                                PositionEvent: request.promoId,
                                EventDetails: 'promotion either expired/invalid',
                                LocationEvent: 'offer page',
                            });
                        }
                        return of(loadTargetPromotionData_Fail());
                    }),
                ),
            ),
        );
    });

    loadCashbackData_Success$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadTargetPromotionData_Success),
            map((action: { metadata: TargetPromotionMetadata; content: TargetPromotionContent }) => {
                const details = new CashbackDetails();
                details.cashbackMetadata = action.metadata as CashbackMetaData;
                details.cashbackContent = action.content as CashbackContent;
                return details;
            }),
            map((response) => loadCashbackData_Success(response)),
        );
    });

    loadOptinCashBackData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userOptedInForPromotion.type),
            map((action: { promoId: string }) => action.promoId),
            mergeMap((promoId) =>
                this.cashbackServie.optedIn(promoId).pipe(
                    map((response: CashbackDetails) => loadCashbackDataOptin_Success(response)),
                    catchError(() => {
                        of(userOptInFailedForPromotion());
                        return EMPTY;
                    }),
                ),
            ),
        );
    });

    userOptInSuccessForPromotion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCashbackDataOptin_Success),
            map((response) =>
                userOptInSuccessForPromotion({
                    optinDetails: response.cashbackMetadata.optinDetails,
                    optinContent: response.cashbackContent.optinContent,
                }),
            ),
        );
    });

    loadCashbackData_SuccessOnOptin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCashbackDataOptin_Success),
            map((response) => loadCashbackData_Success(response)),
        );
    });

    updateResultsContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCashbackDataOptin_Success),
            map((response) => updateResultsContent({ resultsContent: response.cashbackContent.resultsContent })),
        );
    });

    updateTabs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCashbackDataOptin_Success),
            map((response) => updateTabs({ tabs: response.cashbackContent.tabs })),
        );
    });

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(cashbackInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(cashbackDestroyed.type))))),
        );
    }

    constructor(
        private actions$: Actions,
        private cashbackServie: CashbackService,
        private trackingModelService: TrackingModelService,
    ) {}
}
