import { Injectable } from '@angular/core';

import { ClientConfig, ClientConfigProductName, ClientConfigService } from '@frontend/vanilla/core';

@ClientConfig({ key: 'globalSearch', product: ClientConfigProductName.HOST })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: globalSearchConfigfactory,
})
export class GlobalSearchClientConfig {
    // appId: string;
    // configPath: string;
    contextPath: string | undefined;
    // indexPath: string;
    // isEnabled: boolean;
    searchLanguage: string | undefined;
    //targetId: string;
    // presentation: IClientPresentation;
    transportToken: string | undefined;
    //legacyMode: boolean;
    assetPaths: IAssetPaths | undefined;
    stickyicondragabble: boolean | undefined;
}
export interface IAssetPaths {
    scripts: string[];
    styles: string[];
}

export function globalSearchConfigfactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(GlobalSearchClientConfig);
}
