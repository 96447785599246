import { CriteriaCardDetails } from '../../common/models/my-cards/criteria-card/criteria-card-details';
import { CriteriaCardStatus } from '../../common/models/my-cards/criteria-card/criteria-card-status';

export class ClickCardStoreModel {
    criteriaTitle: string;
    criteriaEarnedTitle: string;
    criteriaCardStatus: CriteriaCardStatus;
    toastrDetails: CriteriaCardDetails;
    receivedFreeCard: boolean;
}
