import { EventEmitter, Injectable } from '@angular/core';

import { SearchAppLoadedEventIdentifier, SearchAppQueryEventIdentifier, SearchAppQueryResultEventIdentifier } from '../events';
import { IAppContext } from './app';
import { IContentContext } from './content';
import { IIndexingContext } from './indexing';
import { IClientPresentation } from './presentation';
import { IGlobalSearchContext } from './search';
import { ISecurityContext } from './security';

@Injectable({
    providedIn: 'root',
})
export class SearchContextService implements IGlobalSearchContext {
    private readonly isAppEnabledQuery = 'isappenabled';
    private readonly queryToken = 'sticky-entry-query-isappenabled';
    private searchContext: IGlobalSearchContext | undefined;

    get appContext(): IAppContext | undefined {
        return this.searchContext?.appContext;
    }

    get contentContext(): IContentContext | undefined {
        return this.searchContext?.contentContext;
    }

    get indexingContext(): IIndexingContext | undefined {
        return this.searchContext?.indexingContext;
    }

    get securityContext(): ISecurityContext | undefined {
        return this.searchContext?.securityContext;
    }

    get presentationContext(): IClientPresentation | undefined {
        return this.searchContext?.presentationContext;
    }

    public contextAvailable: EventEmitter<IGlobalSearchContext>;

    constructor() {
        this.contextAvailable = new EventEmitter<IGlobalSearchContext>(true);
        this.registerEventHandlers();
        this.raiseIsAppEnabledQuery();
    }

    private setContext(searchContext: IGlobalSearchContext) {
        this.searchContext = searchContext;
        this.contextAvailable.emit(this.searchContext);
    }

    private registerEventHandlers() {
        addEventListener(SearchAppLoadedEventIdentifier, this.searchAppLoadedEventHandler);
        addEventListener(SearchAppQueryResultEventIdentifier, this.searchAppQueryResultHandler);
    }

    private readonly searchAppLoadedEventHandler = (messageEvent: CustomEventInit) => {
        if (messageEvent && messageEvent.detail && messageEvent.detail?.context) {
            const context: IGlobalSearchContext = messageEvent.detail?.context;
            this.setContext(context);
        }
    };

    private readonly searchAppQueryResultHandler = (customEvent: CustomEventInit) => {
        if (
            customEvent &&
            customEvent?.detail &&
            customEvent?.detail?.query &&
            customEvent?.detail?.queryToken &&
            customEvent?.detail?.queryToken === this.queryToken &&
            customEvent?.detail?.query === this.isAppEnabledQuery
        ) {
            this.setContext(customEvent?.detail?.context);
        }
    };

    private raiseIsAppEnabledQuery() {
        dispatchEvent(
            new CustomEvent(SearchAppQueryEventIdentifier, {
                detail: {
                    query: this.isAppEnabledQuery,
                    queryToken: this.queryToken,
                },
            }),
        );
    }
}
