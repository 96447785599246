import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prPromoCommon', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: commonConfigFactory,
})
export class CommonClientConfiguration extends LazyClientConfigBase {
    triggerGoogleTagManagerClientInjection: boolean;
    googleTagManagerInjectionDelay: number;
}

// eslint:disable-next-line:typedef
export function commonConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(CommonClientConfiguration);
}
