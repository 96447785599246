import { createReducer, on } from '@ngrx/store';

import { CashbackDetails } from '../cashback-models/cashback-details';
import { CashbackStoreModel } from '../cashback-models/cashback-store-model';
import { cashbackDestroyed, loadCashbackData_Success } from './cashback-actions';

export const initialState = new CashbackStoreModel();

export const cashbackReducer = createReducer(
    initialState,
    on(loadCashbackData_Success, (state, action) => ({
        ...state,
        ...getCashbackStoreModel(action),
    })),
    on(cashbackDestroyed, () => initialState),
);

function getCashbackStoreModel(cashbackDetails: CashbackDetails) {
    const cashbackContent = cashbackDetails.cashbackContent;
    return {
        preRequisite: cashbackContent.preRequisite,
        preRequisiteProgress: cashbackContent.preRequisiteProgress,
        myProgressContent: cashbackContent.myProgressContent,
    };
}
