import { HammerGestureConfig } from '@angular/platform-browser';

import * as Hammer from 'hammerjs';

export class HammerConfig extends HammerGestureConfig {
    override overrides = <any>{
        swipe: { enable: true, direction: Hammer.DIRECTION_ALL },
        pan: { enable: true, direction: Hammer.DIRECTION_ALL },
    };
}
