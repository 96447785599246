/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { IGlobalSearchContext } from '../../context/search';
import { SearchContextService } from '../../context/service';
import { SearchIndexLoadEvent } from '../../events';

@Injectable()
export class IndexLoaderClientService {
    private pendingLoad = false;
    private pendingReload = false;
    private searchContext: IGlobalSearchContext;

    constructor(private searchContextService: SearchContextService) {
        this.searchContext = this.searchContextService;
        if (this.searchContextService) {
            this.searchContextService.contextAvailable.subscribe((searchContext: IGlobalSearchContext) => {
                this.searchContext = searchContext;
                if (this.pendingLoad) {
                    this.loadIndex();
                    this.pendingLoad = false;
                }
                if (this.pendingReload) {
                    this.loadIndex();
                    this.pendingReload = false;
                }
            });
        }
    }

    loadIndex(): void {
        console.info('[GlobalSearch]', '[ProductStub]', '[IndexLoaderClientService]', '[loadIndex]');
        if (this.searchContext && this.searchContext.appContext) {
            this.reloadIndex();
        } else {
            this.pendingLoad = true;
        }
    }

    reloadIndex(): void {
        console.info('[GlobalSearch]', '[ProductStub]', '[IndexLoaderClientService]', '[reloadIndex]');
        dispatchEvent(new CustomEvent(SearchIndexLoadEvent));
    }
}
