import { Injectable } from '@angular/core';

import { OfferContentConfiguration } from '@frontend/promo-homewidget';
import { LastKnownProductService, NavigationService, Page } from '@frontend/vanilla/core';

import { PromoClientConfiguration } from '../client-configs/promo-config';

@Injectable({
    providedIn: 'root',
})
export class LogoutHandlerService {
    private lastKnownProduct: string = 'lastknownproduct';

    constructor(
        private promoConfig: PromoClientConfiguration,
        private offerContentConfiguration: OfferContentConfiguration,
        private navigationService: NavigationService,
        private page: Page,
        private lastKnownProductService: LastKnownProductService,
    ) {}

    handleLogout() {
        let redirectUrl = `/${this.offerContentConfiguration.homePage}`;
        const presentUrl = window.location.pathname;
        const baseUrl = this.promoBaseUrl();
        const url = presentUrl.replace(baseUrl, '');
        let matchFound = false;

        for (let i = 0; i < this.promoConfig?.logoutRedirects?.length; i++) {
            const config = this.promoConfig.logoutRedirects[i];

            for (let j = 0; j < config?.fromUrl?.length; j++) {
                const configUrl = config.fromUrl[j];
                const regex = new RegExp(`^(${configUrl})$`);

                if (regex.test(url) && config.toUrl) {
                    matchFound = true;

                    redirectUrl =
                        config.toUrl.toLowerCase() === this.lastKnownProduct
                            ? this.lastKnownProductService?.get()?.url
                            : `/${this.page.product}/${config.toUrl}`;

                    break;
                }
            }
            if (matchFound) break;
        }

        return redirectUrl;
    }

    promoBaseUrl = () => {
        return `/${this.navigationService.location.culture}/${this.page.product}/`;
    };
}
