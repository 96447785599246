import { Injectable } from '@angular/core';

import { CommonService, OfferContentConfiguration } from '@frontend/promo-homewidget';
import {
    AppInfoConfig,
    AuthService,
    ClientConfigService,
    CookieService,
    MenuActionsService,
    MenuItemsService,
    MenuSection,
    NavigationService,
    OnAppInit,
    ProductHomepagesConfig,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';

import { PromoLatamNavigationConfiguration } from '../client-configs/latam-navigation-config';
import { CommonClientConfiguration } from '../client-configs/promo-common-config';
import { PromoClientConfiguration } from '../client-configs/promo-config';
import { PromoFormattersConfiguration } from '../client-configs/promo-formatters-config';
import { VIPHubConfiguration } from '../client-configs/viphub-config';
import { LogoutHandlerService } from '../common/logout-handler.service';
import { TagManagerRendererService } from './tagmanager-renderer.service';

@Injectable()
export class PromoUserEventBootstrapService implements OnAppInit {
    private deactivate = new Subject<void>();

    constructor(
        private authService: AuthService,
        private user: UserService,
        private clientConfigService: ClientConfigService,
        public commonService: CommonService,
        private navigationService: NavigationService,
        private logoutHandler: LogoutHandlerService,
        private tagManagerRenderer: TagManagerRendererService,
        private menuService: MenuItemsService,
        private offerContentConfiguration: OfferContentConfiguration,
        private promoConfig: PromoClientConfiguration,
        private vipHubConfiguration: VIPHubConfiguration,
        private commonClientConfig: CommonClientConfiguration,
        private cookieService: CookieService,
        private promoFormattersConfig: PromoFormattersConfiguration,
        private latamNavigationConfiguration: PromoLatamNavigationConfiguration,
        private productHomePagesConfig: ProductHomepagesConfig,
        private appInfoConfig: AppInfoConfig,
        private menuActionsService: MenuActionsService,
    ) {}

    async onAppInit() {
        await this.loadCommonClientConfigs();
        if (this.promoFormattersConfig?.localTimeZones) {
            const timeZone = new Date()?.toTimeString()?.split('(')[1]?.replace(')', '')?.toLowerCase();
            const deviceTimeZone = this.promoFormattersConfig?.localTimeZones[timeZone];
            this.cookieService.put('deviceTimeZoneId', deviceTimeZone);
        }

        this.tagManagerRenderer.renderGTMTag();
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.clientConfigService.reload([PromoClientConfiguration]);
        });

        this.navigationService.locationChange.subscribe(() => {
            const redirectUrl = this.logoutHandler.handleLogout();
            this.authService.changeRedirectUrlAfterLogout(redirectUrl);
        });

        if (this.offerContentConfiguration.isEpcotEnabled) {
            this.menuService.setActive(MenuSection.BottomNav, 'promotions_epcot');
        }
        register();
        this.menuActionsService.register('gotoHome', () => {
            const product = this.appInfoConfig.product;
            switch (product.toLocaleLowerCase()) {
                case 'sportsbook':
                case 'sports':
                case 'betting': {
                    this.navigationService.goTo(this.productHomePagesConfig.sports);
                    break;
                }
                case 'casino': {
                    this.navigationService.goTo(this.productHomePagesConfig.casino);
                    break;
                }
                case 'poker': {
                    this.navigationService.goTo(this.productHomePagesConfig.poker);
                    break;
                }
                case 'bingo': {
                    this.navigationService.goTo(this.productHomePagesConfig.bingo);
                    break;
                }
                case 'portal': {
                    this.navigationService.goTo(this.productHomePagesConfig.portal);
                    break;
                }
                default: {
                    this.navigationService.goTo(this.productHomePagesConfig.promo);
                    break;
                }
            }
        });
    }

    async loadCommonClientConfigs() {
        const configs = [] as any;
        if (!this.offerContentConfiguration.isConfigReady) configs.push(OfferContentConfiguration);
        if (!this.latamNavigationConfiguration.isConfigReady) configs.push(PromoLatamNavigationConfiguration);
        if (!this.promoConfig.isConfigReady) configs.push(PromoClientConfiguration);
        if (!this.commonClientConfig.isConfigReady) configs.push(CommonClientConfiguration);
        if (!this.promoFormattersConfig.isConfigReady) configs.push(PromoFormattersConfiguration);
        if (!this.vipHubConfiguration.isConfigReady) configs.push(VIPHubConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
    }

    // eslint:disable-next-line:no-empty

    // eslint:disable-next-line:typedef

    onDeactivate(): void | Promise<void> {
        this.deactivate.next();
        this.deactivate.complete();
    }
}
