import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { AppModule } from './app.module';
import { appRoutes } from './app.routes';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

performance.mark('app-boot-start');
bootloader().then(() => {
    bootstrapApplication(AppComponent, {
        providers: [
            provideRouter(appRoutes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })),
            provideAnimations(),
            provideVanillaCore(),
            provideLoaders(),
            importProvidersFrom(AppModule),
        ],
    })
        .then(() => {
            performance.mark('app-boot-end');
            performance.measure('app-boot', 'app-boot-start', 'app-boot-end');
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
});
