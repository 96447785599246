import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { PromoDetailPageConfiguration } from '@frontend/promo-utils';
import { ClientConfigService } from '@frontend/vanilla/core';

@Injectable()
export class StaticPromotionGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private detailPageClientConfig: PromoDetailPageConfiguration,
    ) {}

    async canActivate() {
        if (!this.detailPageClientConfig.isConfigReady) await this.clientConfigService.load('', [PromoDetailPageConfiguration]);
        return true;
    }
}
