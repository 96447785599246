import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { STORE_PREFIX } from '@frontend/vanilla/core';
import { GlobalSearchModule } from '@globalsearch/productstub';
import { LetDirective } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BootstrapModule } from './bootstrap/bootstrap.module';
import { ClientConfigActivateGuard } from './client-configs/guards/client-config-activate.guard';
import { FilterConfigActivateGuard } from './client-configs/guards/filter-config-activate.guard';
import { OffersEnhancementGuard } from './client-configs/guards/offers-enhancement-guard';
import { OffersGuard } from './client-configs/guards/offers.guard';
import { VIPHubRouteGuard } from './client-configs/guards/viphub-route.guard';
import { CoinEconomyGuard } from './coin-economy/config/coin-economy.guard';
import { UserStatusCheckGuard } from './coin-economy/config/user-status.guard';
import { AuthorizationGuard } from './common/authorization.guard';
import { CommonDetailsResourceService } from './details/Common/common-details-resource.service';
import { CommonDetailsResolver } from './details/Common/common-details.resolver';
import { BetAndGetDetailsV2Resolver } from './details/bet-and-get-details-v2/bet-and-get-details-v2.resolver';
import { BetAndGetDetailsResourceService } from './details/bet-and-get/bet-and-get-service/bet-and-get-details-resource.service';
import { BetAndGetDetailsResolver } from './details/bet-and-get/bet-and-get-service/bet-and-get-details.resolver';
import { BingoTicketsDetailsResourceService } from './details/bingoTickets/details/bingoTicketsService/bingo-tickets-details-resource.service';
import { BingoTicketsDetailsResolver } from './details/bingoTickets/details/bingoTicketsService/bingo-tickets-details.resolver';
import { BonusDetailsResourceService } from './details/bonus/details/bonus-service/bonus-details-resource.service';
import { BonusDetailsResolver } from './details/bonus/details/bonus-service/bonus-details.resolver';
import { BonusSpinsDetailsV2Resolver } from './details/bonusspins/bonusspins-details.resolver';
import { BratContentResourceService } from './details/brat/services/brat-content.resolver.service';
import { BratResolver } from './details/brat/services/brat.resolver';
import { BratContentResolver } from './details/brat/store/brat-content.resolver';
import { FreebetDetailsV2Resolver } from './details/freebet-details-V2/freebet-details-V2-resolver';
import { FreebetDetailsResourceService } from './details/freebet/details/freebet-service/freebet-details-resource.service';
import { FreebetsDetailsResolver } from './details/freebet/details/freebet-service/freebets-details.resolver';
import { FreeSpinsDetailsResourceService } from './details/freespins/details/freespins-service/freespins-details-resource.service';
import { FreeSpinsDetailsResolver } from './details/freespins/details/freespins-service/freespins-details.resolver';
import { GoldenChipsDetailsResourceService } from './details/goldenchips/details/goldenchips-service/goldenchips-details-resource.service';
import { GoldenChipsDetailsResolver } from './details/goldenchips/details/goldenchips-service/goldenchips-details.resolver';
import { OddBoostDetailsResolverV2 } from './details/odd-boost-V2/odd-boost-details-V2.resolver';
import { OddBoostDetailsResourceService } from './details/odd-boost/details/odd-boost-service/odd-boost-details-resource.service';
import { OddBoostDetailsResolver } from './details/odd-boost/details/odd-boost-service/odd-boost-details.resolver';
import { RiskFreeBetDetailsV2Resolver } from './details/risk-free-bet-V2/risk-free-bet-details-V2.resolver';
import { RiskFreeBetDetailsResourceService } from './details/risk-free-bet/risk-free-bet-service/risk-free-bet-details-resource.service';
import { RiskFreeBetDetailsResolver } from './details/risk-free-bet/risk-free-bet-service/risk-free-bet-details.resolver';
import { StaticPromotionGuard } from './details/targetedPromotions/static-promotion/static-promotion.guard';
import { AppEffects } from './effects/app.effects';
import { PromoHttpErrorInterceptor } from './errorHandling/promo-http-error.interceptor';
import { LobbyActiveGuard } from './lobby/lobby.guard';
import { HammerConfig } from './myHammer.config';
import { OffersModule } from './offers/offers.module';
import { PromoInternalRouteGuard } from './promo-internal-route-guard';
import { metaReducers, reducers } from './reducers';
import { RootServicesModule } from './root-services.module';
import { SmartRewardGuard } from './smart-rewards/smart-reward.guard';
import { SplashPageGuard } from './splash-page/splash-page.guard';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        GlobalSearchModule.forRoot(),
        OffersModule,
        HammerModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        EffectsModule.forRoot([AppEffects]),
        RootServicesModule,
        LetDirective,
        BootstrapModule.forRoot(), // Bootstrap module should always be imported at the end, otherwise logout in mobile view won't work.
    ],
    providers: [
        { provide: STORE_PREFIX, useValue: 'promo.' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PromoHttpErrorInterceptor,
            multi: true,
        },
        CommonDetailsResolver,
        CommonDetailsResourceService,
        AuthorizationGuard,
        ClientConfigActivateGuard,
        FilterConfigActivateGuard,
        OffersEnhancementGuard,
        BonusSpinsDetailsV2Resolver,
        FreeSpinsDetailsResourceService,
        SmartRewardGuard,
        FreebetsDetailsResolver,
        FreebetDetailsV2Resolver,
        FreebetDetailsResourceService,
        RiskFreeBetDetailsResolver,
        RiskFreeBetDetailsV2Resolver,
        RiskFreeBetDetailsResourceService,
        BetAndGetDetailsV2Resolver,
        BetAndGetDetailsResourceService,
        BetAndGetDetailsResolver,
        OddBoostDetailsResolverV2,
        OddBoostDetailsResourceService,
        VIPHubRouteGuard,
        OffersGuard,
        OddBoostDetailsResolver,
        CoinEconomyGuard,
        UserStatusCheckGuard,
        StaticPromotionGuard,
        BonusDetailsResourceService,
        BonusDetailsResolver,
        FreeSpinsDetailsResolver,
        BratResolver,
        BratContentResolver,
        BratContentResourceService,
        BingoTicketsDetailsResolver,
        BingoTicketsDetailsResourceService,
        GoldenChipsDetailsResolver,
        GoldenChipsDetailsResourceService,
        LobbyActiveGuard,
        PromoInternalRouteGuard,
        SplashPageGuard,
    ],
})
export class AppModule {}
