import { createReducer, on } from '@ngrx/store';

import { ClickCardDetails } from '../click-card-models/click-card-details';
import { ClickCardStoreModel } from '../click-card-models/click-card-store-model';
import { clickCardDestroyed, loadClickCardData_Success, loadToastr } from './click-card.actions';

export const initialState: ClickCardStoreModel = new ClickCardStoreModel();

export const clickCardReducer = createReducer(
    initialState,
    on(loadClickCardData_Success, (state, action) => ({
        ...state,
        ...getClickCardStoreModel(action),
    })),
    on(loadToastr, (state, action) => ({
        ...state,
        receivedFreeCard:
            action.clickCardMetadata.playerPromoConfiguration.earnedCardDetails.filter((c) => c.type.toLowerCase() === 'free').length > 0,
    })),
    on(clickCardDestroyed, () => initialState),
);

function getClickCardStoreModel(clickCardDetails: ClickCardDetails): ClickCardStoreModel {
    const clickCardStoreModel = new ClickCardStoreModel();
    const clickCardContent = clickCardDetails.clickCardContent;

    const clickCardCriteriaTitle = clickCardContent.myCardsDetails?.criteriaCard;
    if (clickCardCriteriaTitle !== undefined) {
        clickCardStoreModel.criteriaEarnedTitle = clickCardCriteriaTitle.criteriaEarnedTitle;
        clickCardStoreModel.criteriaTitle = clickCardCriteriaTitle.criteriaTitle;
        clickCardStoreModel.criteriaCardStatus = clickCardContent.criteriaCardStatus;
        clickCardStoreModel.toastrDetails = clickCardContent.toastrDetails;
    }
    return clickCardStoreModel;
}
