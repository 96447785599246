<div id="container" class="global-search-stickycomponent">
    <div *ngIf="isEnabled" [ngClass]="wrapperClass" (click)="launchSearch()" id="item">
        <a class="fab-search-container">
            <span *ngIf="canDisplayIcon" [ngClass]="iconClass"></span>
            <!-- ICONS PLACEHOLDER class for the icon "theme-search" to be configured via sitecore -->
            <!-- OR we want to have both options icon or image -->
            <img *ngIf="canDisplayImage" [src]="imageSource" alt="" class="fab-search-img" />
            <!-- IMAGE PLACEHOLDER src to come from Sitecore, optional additional class from sitecore-->
        </a>
    </div>
</div>
