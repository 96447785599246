import { ViewTemplateForClient } from '@frontend/vanilla/core';

import { BaseDetailsDataPoint } from '../../../../base-details/base-details.model';
import { TargetPromotionContent } from '../../../common/models/target-promotion-content';
import { TargetPromotionMetadata } from '../../../common/models/target-promotion-metadata';

export class StaticPromotionDetails {
    public staticPromotionContent: StaticPromotionContent;
    public staticPromotionMetaData: StaticPromotionMetaData;
}

export class StaticPromotionContent extends TargetPromotionContent {
    registerTitle: string;
    manualDataPoints: BaseDetailsDataPoint[];
}

export class StaticPromotionMetaData extends TargetPromotionMetadata {}

export class StaticPromotionClientContent {
    public content: ViewTemplateForClient;
}
