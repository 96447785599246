import { Injectable, NgZone } from '@angular/core';

import { ClientConfigDiff, ClientConfigService, TrackingService } from '@frontend/vanilla/core';

import { __CLIENT_CONFIG_UPDATE_AVAILABLE__, __READ_CLIENT_CONFIG__, __TRACK__ } from '../../events';
import { IEventTrackingInput } from './event-tracking.input';

@Injectable()
export class GlobalSearchWebTrackingService {
    constructor(
        private readonly trackingService: TrackingService,
        private readonly clientConfigService: ClientConfigService,
        private _ngZone: NgZone,
    ) {
        this.clientConfigService.updates.subscribe((clientConfigDiff: ClientConfigDiff) => {
            dispatchEvent(new CustomEvent(__CLIENT_CONFIG_UPDATE_AVAILABLE__, { detail: clientConfigDiff }));
        });
    }
    registerHandlers() {
        addEventListener(__TRACK__, this.trackingHandler, false);
        this._ngZone.runOutsideAngular(() => {
            addEventListener(__READ_CLIENT_CONFIG__, this.readClientConfighandler, false);
        });
    }

    trackingHandler = (customevent: Event) => {
        const trackingData = (customevent as CustomEvent)?.detail;
        if (trackingData) {
            const eventName = trackingData.eventName;
            const data = trackingData.data;
            const options = trackingData.options;
            this.trackingService.triggerEvent(eventName, data, options);
        }
    };

    readClientConfighandler = (customevent: Event) => {
        if ((customevent as CustomEvent)?.detail?.clientConfig) {
            (customevent as CustomEvent).detail.clientConfig = (window as any).clientConfig;
            // to read  clientconfig from vanilla
        }
    };

    trackClick(eventInput: IEventTrackingInput): void {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': eventInput.componentCategoryEvent,
            'component.LabelEvent': eventInput.componentLabelEvent,
            'component.ActionEvent': eventInput.componentActionEvent,
            'component.PositionEvent': eventInput.componentPositionEvent,
            'component.LocationEvent': eventInput.componentLocationEvent,
            'component.EventDetails': eventInput.componentEventDetails,
            'component.URLClicked': eventInput.componentURLClicked,
        });
    }
    trackTerm(eventInput: IEventTrackingInput): void {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': eventInput.componentCategoryEvent,
            'component.LabelEvent': eventInput.componentLabelEvent,
            'component.ActionEvent': eventInput.componentActionEvent,
            'component.PositionEvent': eventInput.componentPositionEvent,
            'component.LocationEvent': eventInput.componentLocationEvent,
            'component.EventDetails': eventInput.componentEventDetails,
            'component.URLClicked': eventInput.componentURLClicked,
        });
    }
}
