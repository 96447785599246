import { ModuleWithProviders, NgModule } from '@angular/core';

// import { SearcherModule } from './searcher/searcher.module';
import { runOnAppInit } from '@frontend/vanilla/core';

import { GlobalSearchEntryPointsModule } from './entrypoints/entrypoints.module';
// import { TranslatorService } from './translate/translator.service';
import { GlobalSearchBootstrapService } from './shared/bootstrapping/bootstrap.service';
import { IndexLoaderClientService } from './shared/content/index-loader.service';
import { SearchIndexLoaderRegistrationService } from './shared/indexing/index-loader-registration.service';
import { GlobalSearchWebTrackingService } from './shared/webanalytics/trackingservice';

@NgModule({
    imports: [GlobalSearchEntryPointsModule],
})
export class GlobalSearchModule {
    public static forRoot(): ModuleWithProviders<GlobalSearchModule> {
        return {
            ngModule: GlobalSearchModule,
            providers: [
                runOnAppInit(GlobalSearchBootstrapService),
                GlobalSearchWebTrackingService,
                SearchIndexLoaderRegistrationService,
                IndexLoaderClientService,
                //  TranslatorService,
            ],
        };
    }
}
