import { Injectable } from '@angular/core';

import { UserLoginEvent, UserLogoutEvent, UserService } from '@frontend/vanilla/core';
import { filter } from 'rxjs/operators';

import { IndexLoaderClientService } from '../content/index-loader.service';

@Injectable()
export class SearchIndexLoaderRegistrationService {
    constructor(
        private readonly userService: UserService,
        private readonly indexLoaderClientService: IndexLoaderClientService,
    ) {}

    register(): void {
        this.userService.events.pipe(filter((e: any) => e instanceof (UserLoginEvent || UserLogoutEvent))).subscribe(() => {
            this.indexLoaderClientService.reloadIndex();
        });
    }
}
