import { Injectable } from '@angular/core';

import { TagManagerService, TrackingService, WindowRef } from '@frontend/vanilla/core';

import { CommonClientConfiguration } from '../client-configs/promo-common-config';
import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class TagManagerRendererService {
    private isGoogleTagManagerLoaded: boolean = false;

    constructor(
        private tagManagerService: TagManagerService,
        private vanillaTrackingService: TrackingService,
        private commonClientConfig: CommonClientConfiguration,
        private windowRef: WindowRef,
    ) {}

    renderGTMTag(): void {
        if (this.commonClientConfig && this.commonClientConfig.triggerGoogleTagManagerClientInjection && !this.isGoogleTagManagerLoaded) {
            // Fire App Loaded Event for use as a trigger in GTM - Should be the first event in DataLayer
            this.vanillaTrackingService.triggerEvent('Vanilla_Portal_App_Loaded');

            //Load GTM with a delay
            this.windowRef.nativeWindow.setTimeout(() => {
                this.loadGTM();
            }, this.commonClientConfig.googleTagManagerInjectionDelay);
        }
    }

    private loadGTM(): void {
        // Load GTM Script
        this.tagManagerService.whenReady.subscribe(() => {
            this.tagManagerService.load('GoogleTagManagerRenderer');
        });
        this.isGoogleTagManagerLoaded = true;
    }
}
