import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prFormatters', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: PromoFormattersConfigFactory,
})
export class PromoFormattersConfiguration extends LazyClientConfigBase {
    localTimeZones: { [key: string]: string };
}

export function PromoFormattersConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoFormattersConfiguration);
}
