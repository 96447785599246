import { Injectable, Type } from '@angular/core';

import { DeviceService, DynamicComponentsRegistry, DynamicLayoutService, HeaderService, MenuActionsService, OnAppInit } from '@frontend/vanilla/core';
import { HeaderSearchService } from '@frontend/vanilla/shared/header';

import { GlobalSearchClientConfig } from '../../config/client-config';
// import { HeaderLeftMenuEntryComponent } from '../../entrypoints/header-leftmenu-entry.component';
import { StickyEntryComponent } from '../../entrypoints/sticky-entry.component';
import { __BOOTSTRAP_APP__, __LAUNCH_SEARCH__ } from '../../events';
import { SearchIndexLoaderRegistrationService } from '../indexing/index-loader-registration.service';
import { GlobalSearchWebTrackingService } from '../webanalytics/trackingservice';

@Injectable()
export class GlobalSearchBootstrapService implements OnAppInit {
    bottom: string | undefined;
    isEnabled: false | undefined;

    constructor(
        private readonly dynamicComponentsRegistry: DynamicComponentsRegistry,
        private readonly tracking: GlobalSearchWebTrackingService,
        private readonly deviceService: DeviceService,
        private readonly headerService: HeaderService,
        private readonly menuActionsService: MenuActionsService,
        private readonly headerSearchService: HeaderSearchService,
        private readonly searchIndexLoaderRegistrationService: SearchIndexLoaderRegistrationService,
        private readonly globalSearchConfig: GlobalSearchClientConfig,
        private readonly dynamicLayoutService: DynamicLayoutService,
    ) {}

    async onAppInit(): Promise<any> {
        const GS_COMPONENTS_MAP: { [key: string]: () => Promise<Type<any>> } = {
            'gs-search': () => import('../../entrypoints/header-leftmenu-entry.component').then((x) => x.HeaderLeftMenuEntryComponent),
        };
        this.tracking.registerHandlers();
        this.dynamicComponentsRegistry.registerLazyComponent('HEADER', 'global-search', GS_COMPONENTS_MAP['gs-search']);
        this.headerService.whenReady.subscribe(() => {
            // this.headerService.setHeaderComponent('global-search', HeaderLeftMenuEntryComponent);
            this.headerSearchService.clickEvent.subscribe(() => {
                dispatchEvent(new CustomEvent(__LAUNCH_SEARCH__, { detail: { launchChannel: 'mobile', launchedFrom: 'epcot' } }));
            });
        });
        this.menuActionsService.register('openGlobalSearch', () => {
            dispatchEvent(new CustomEvent(__LAUNCH_SEARCH__, { detail: { launchChannel: 'mobile', launchedFrom: 'bottom' } }));
        });

        if (this.deviceService.isTouch) {
            this.dynamicLayoutService.addComponent('app', StickyEntryComponent, null);
        }

        addEventListener(__BOOTSTRAP_APP__, () => {
            this.globalSearchConfig.assetPaths?.scripts.forEach((scriptpath) => {
                if ((scriptpath || '')?.trim()) {
                    const scriptElement = document.createElement('script');
                    scriptElement.src = (scriptpath || '')?.trim();
                    document.body.appendChild(scriptElement);
                }
            });
            this.globalSearchConfig.assetPaths?.styles.forEach((stylepath) => {
                if ((stylepath || '')?.trim()) {
                    const styleElement = document.createElement('link');
                    styleElement.rel = 'stylesheet';
                    styleElement.type = 'text/css';
                    styleElement.href = (stylepath || '')?.trim();
                    document.body.appendChild(styleElement.cloneNode(true));
                }
            });
        });
        this.searchIndexLoaderRegistrationService.register();
    }
}
