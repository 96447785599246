import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, ModuleWithProviders, NgModule } from '@angular/core';

import { MenuActionsService } from '@frontend/vanilla/core';

import { HeaderLeftMenuEntryComponent } from './header-leftmenu-entry.component';
import { StickyEntryComponent } from './sticky-entry.component';

@NgModule({
    imports: [CommonModule],
    declarations: [HeaderLeftMenuEntryComponent, StickyEntryComponent],
    providers: [MenuActionsService],
})
export class GlobalSearchEntryPointsModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(HeaderLeftMenuEntryComponent, StickyEntryComponent); // Or some other component
    }
    public static forRoot(): ModuleWithProviders<GlobalSearchEntryPointsModule> {
        return {
            ngModule: GlobalSearchEntryPointsModule,
        };
    }
}
