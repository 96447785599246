import { ModuleWithProviders, NgModule } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { PromoUserEventBootstrapService } from './pomo-user-events-bootstrap.service';

@NgModule()
export class BootstrapModule {
    public static forRoot(): ModuleWithProviders<BootstrapModule> {
        return {
            ngModule: BootstrapModule,
            providers: [runOnAppInit(PromoUserEventBootstrapService)],
        };
    }
}
