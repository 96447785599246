import { Injectable } from '@angular/core';

import { CommonService } from '@frontend/promo-homewidget';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { updateResultsContent } from '../../targetedPromotions/common/store/target-promotions.actions';
import { Brat } from '../model/brat-details.model';
import { BratHttpService } from '../services/brat-http.service';
import { BratActions } from './action-types';

@Injectable()
export class BratEffects {
    loadBrat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BratActions.BRAT_LOADING),
            map((action: { bratId: string }) => action.bratId),
            mergeMap((bratId) =>
                this.bratHttpService.getBratDetails(bratId).pipe(
                    map((response: Brat) => BratActions.BRAT_LOADED({ brat: response })),
                    catchError(() => of(BratActions.BRAT_LOAD_FAILED({ errorMessage: 'OOPS' }))),
                ),
            ),
        );
    });

    updateResultsContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BratActions.BRAT_LOADED),
            map((action: { brat: Brat }) => action.brat),
            map((brat) => updateResultsContent({ resultsContent: brat.bratContent.resultsContent })),
        );
    });

    loadDataFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(BratActions.BRAT_LOAD_FAILED.type),
                tap(() => {
                    this.commonService.goToOverviewPage();
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private bratHttpService: BratHttpService,
        private commonService: CommonService,
    ) {}
}
