import { Injectable } from '@angular/core';

import { SmartRewardConfiguration } from '@frontend/promo-smartwidget';
import { PromoDistributedCacheConfiguration } from '@frontend/promo-utils';
import { ClientConfigService } from '@frontend/vanilla/core';

@Injectable()
export class SmartRewardGuard {
    constructor(
        private clientConfigService: ClientConfigService,
        private smartRewardConfig: SmartRewardConfiguration,
        private cacheConfiguration: PromoDistributedCacheConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.smartRewardConfig.isConfigReady) configs.push(SmartRewardConfiguration);
        if (!this.cacheConfiguration.isConfigReady) configs.push(PromoDistributedCacheConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
