import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { ClientConfigService } from '@frontend/vanilla/core';

import { LobbyClientConfiguration } from './lobby.client-config';

@Injectable()
export class LobbyActiveGuard implements CanActivate {
    constructor(
        private config: LobbyClientConfiguration,
        private clientConfigService: ClientConfigService,
    ) {}

    async canActivate() {
        if (!this.config.isConfigReady) await this.clientConfigService.load('', [LobbyClientConfiguration]);
        return true;
    }
}
